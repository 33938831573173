import {post} from '@/utils/request'

//站点列表
export function PostSiteList(data) {
  return post('adminapi/site_list',data)
}
//站点增加
export function PostSiteAdd(data) {
  return post('adminapi/site_add',data)
}
//站点修改
export function PostSiteUpdate(data) {
  return post('adminapi/site_update',data)
}
//站点删除
export function PostSiteDelete(data) {
  return post('adminapi/site_delete',data)
}
//地区列表
export function PostRegionalList(data) {
    return post('adminapi/regional_list',data)
  }