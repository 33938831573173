<template>
    <div class="user">
      <!-- 面包屑导航 -->
      <el-card>
        <!-- 头 -->
        <el-row :gutter="20">
          <el-col :span="5">
            <el-input placeholder="请输入内容" v-model="getInfo.info">
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </el-col>
          <el-col :span="8"> </el-col>
          <el-tooltip
            class="item"
            effect="dark"
            content="增加站点"
            placement="top-start"
          >
            <el-button  type="primary" @click="addPeoBtn">增加站点</el-button>
          </el-tooltip>
        </el-row>
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          v-if="tableData"
        >
        <el-table-column
            align="center"
            prop="site_id"
            label="地区Id"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="site_address"
            label="地址"
          >
          </el-table-column>
        <el-table-column
            align="center"
            prop="site_type_name"
            label="站点类型"
          >
          <!-- <template slot-scope="scope">
             <div v-if="scope.row.site_type==1">餐厅</div>
             <div v-if="scope.row.site_type==2">酒店</div>
             <div v-if="scope.row.site_type==3">房车</div>
             <div v-if="scope.row.site_type==4">停车场</div>
             <div v-if="scope.row.site_type==5">入口</div>
             <div v-if="scope.row.site_type==6">物料点</div>
             <div v-if="scope.row.site_type==7">民宿</div>
          </template> -->
          </el-table-column>
              <el-table-column
            align="center"
            prop="site_name"
            label="站点名称"
          >
          </el-table-column>
              <el-table-column
            align="center"
            prop="site_cover_image"
            label="图片"
          >
          <template slot-scope="scope">
            <img :src="scope.row.site_cover_image" width="120px" height="120px" />
        </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <!-- <el-tooltip
                class="item"
                effect="dark"
                content="查看站点详情"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-search"
                  @click="lookSite(scope.row)"
                ></el-button>
              </el-tooltip> -->
              <el-tooltip
                class="item"
                effect="dark"
                content="修改站点"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-edit"
                  type="primary"
                  @click="updataPicture(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="删除站点"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-delete"
                  type="danger"
                  @click="delPicture(scope.row.site_id)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @current-change="pageChange"
          @size-change="sizeChange"
          :page-sizes="[7, 15, 20]"
          layout="total, sizes, prev, pager, next"
          :page-size="this.getInfo.page_num"
          background
          :total="exp.num"
        >
        </el-pagination>
      </el-card>
       <!-- 增加场地服务标题 -->
      <el-dialog
        :visible.sync="dialogFormVisible"
        @close="userClose(form)"
        :append-to-body="true"
         
      >
      <div slot="title" v-if="updataFalg" class="dialog-footer" >
          <div class="title" >修改站点</div>
        </div>
         <div slot="title" v-else-if="lookFalg" class="dialog-footer" >
          <div class="title" >查看站点</div>
        </div>
        <div slot="title" v-else class="dialog-footer" >
          <div class="title" >增加站点</div>
        </div>
        <div class="diaBox">
        <el-form :model="form" ref="ruleForm"   :rules="rules">
                         <el-form-item label="类型" :label-width="formLabelWidth" prop="site_type">
                            <el-select  v-model="form.site_type" placeholder="请选择">
    <el-option
      v-for="item in siteTypeArr"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
    </el-form-item> 
    <el-form-item label="站点名称" :label-width="formLabelWidth" prop="site_name">
            <el-input v-model="form.site_name"></el-input>
    </el-form-item>     
    <el-form-item
            label="封面图"
            prop="site_cover_image"
            :label-width="formLabelWidth"
          >
            <el-upload
              class="upload-demo"
              :action="imgUrl"
              :on-success="imgSuccess"
              :on-remove="imgRemove"
              :on-exceed="exceed"
              :file-list="fileList"
              :data="imgParam"
              :limit="maxLimit"
              list-type="picture"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="地区" :label-width="formLabelWidth" prop="regional_id">
            <el-select  v-model="form.regional_id" placeholder="请选择">
    <el-option
      v-for="item in siteIdArr"
      :key="item.regional_id"
      :label="item.regional_name"
      :value="item.regional_id">
    </el-option>
  </el-select>
    </el-form-item>  
          <el-form-item label="详情地址" :label-width="formLabelWidth" prop="site_address">
            <el-input v-model="form.site_address"></el-input>
    </el-form-item>  
        </el-form>
         </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button v-if="addFlag" type="primary" @click="siteAddDate('ruleForm')"
            >添 加</el-button
          >
          <el-button v-else-if="lookFalg" type="primary" @click="siteAddDate('ruleForm')"
            >确 认</el-button
          >
         <el-button v-else type="primary" @click="updSiteSer('ruleForm')"
            >修 改</el-button
          >
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  //这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
  //例如：import 《组件名称》 from '《组件路径》';
  import {
    PostSiteList,
    PostSiteAdd,
    PostSiteUpdate,
    PostSiteDelete,
    PostRegionalList
} from "@/api/site/siteList.js"

  export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
          var validateSiteType = (rule, value, callback) => {
          if (this.form.site_type === '') {
            callback(new Error('请选择类型'));
          } else {
            callback();
          }
        };
        var validateSiteName = (rule, value, callback) => {
          if (this.form.site_name === '') {
            callback(new Error('请输入站点名称'));
          } else {
            callback();
          }
        };
        var validateSiteCoverImage = (rule, value, callback) => {
          if (this.form.site_cover_image === '') {
            callback(new Error('请上传图片'));
          } else {
            callback();
          }
        };
        var validateRegionalId = (rule, value, callback) => {
          if (this.form.regional_id === '') {
            callback(new Error('请选择地区'));
          } else {
            callback();
          }
        };
        var validateSiteAddress = (rule, value, callback) => {
          if (this.form.site_address === '') {
            callback(new Error('请输入站点详情地址'));
          } else {
            callback();
          }
        };
      //这里存放数据
      return {
           rules: {
            site_type: [
              { validator: validateSiteType, trigger: 'blur' }
            ],
            site_name: [
              { validator: validateSiteName, trigger: 'blur' }
            ],
            site_cover_image: [
              { validator: validateSiteCoverImage, trigger: 'blur' }
            ],
            regional_id: [
              { validator: validateRegionalId, trigger: 'blur' }
            ],
            site_address: [
              { validator: validateSiteAddress, trigger: 'blur' }
            ],
            
          },
        //总条数
        exp: {
          count: Number,
          num: 0,
        },
        //当前页码
        getInfo: {
          //搜索框
          info: "",
          page_code: 1,
          page_num: 15,
        },
        maxLimit: 1,
        form:{
            site_id:'',//id
            site_type:'',//类型
            site_name:'',//站点名称
            site_cover_image:'',//封面图
            regional_id:'',//地区ID
            site_address:'',//地址
        },
        props:{
          children:'sub',
          value:'id',
          label:'name'
        },
        //表格数据
           tableData: [],
              token: {
          token: window.sessionStorage.getItem("token"),
        },
        formLabelWidth: "100px",
         imgParam: {
          path: "contract/template",
        },
          fileList: [],
          //控制弹窗--新增修改
        dialogFormVisible: false,
        addFlag:false,
        lookFalg:false,
        updataFalg:false,
        siteTypeArr:[
            {
            value:1,
            label:'餐厅'
        },
        {
            value:2,
            label:'酒店'
        },
        {
            value:3,
            label:'房车'
        },
        {
            value:4,
            label:'停车场'
        },
        {
            value:5,
            label:'入口'
        },
        {
            value:6,
            label:'物料点'
        },
        {
            value:7,
            label:'民宿'
        },
        {
            value:8,
            label:'露营'
        },
    ],
    siteIdArr:[]
      };
    },
    //监听属性 类似于data概念
    computed: {
          imgUrl() {
        return this.$store.state.imgUrl;
      },
    },
    //监控data中的数据变化
    watch: {
    //          dialogFormVisible(newVal, oldVal) {
    //     if (!newVal) {
    //       this.form = this.$options.data().form;
    //       this.updataFalg=false;
    //       this.lookFalg=false;
    //       this.addFlag=false;
    //     }
    //   },
    },
    //方法集合
    methods: {
        //站点删除
        delPicture(site_id){
            this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            this.PostSiteDelete({
                site_id:site_id
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
        
        },
        //站点增加
        siteAddDate(formName){
            this.$refs[formName].validate((valid) => {
          if (valid) {
          this.PostSiteAdd(this.form);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
        },
        //站点修改
        updSiteSer(formName){
            this.$refs[formName].validate((valid) => {
          if (valid) {
          this.PostSiteUpdate(this.form);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
        },
      //增加站点
      addPeoBtn(){
          this.dialogFormVisible=true;
          this.addFlag=true;
      },
      //修改站点
      updataPicture(item){
        this.dialogFormVisible=true;
          this.updataFalg=true;
          this.form={
            site_id:item.site_id,//id
            site_type:item.site_type,//类型
            site_name:item.site_name,//站点名称
            site_cover_image:item.site_cover_image,//封面图
            regional_id:item.regional_id,//地区ID
            site_address:item.site_address,//地址
          }
          this.fileList=[{
            url:item.site_cover_image,
            name:item.site_name
          }]
      },
      //修改每页数量
      sizeChange(value) {
        this.getInfo.page_num = value;
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
      };
       this.PostSiteList(data);
        this.tableData = [];
       // this.getPeopleList(data);
      },
      //分页切换
      pageChange(value) {
        this.getInfo.page_code = value;
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
      };
       this.PostSiteList(data);
        this.tableData = [];
      //  this.getPeopleList(data);
      },
            exceed() {
        this.$message({
          message: "数量超出",
          type: "warning",
        });
      },
      //导入图片事件
      imgSuccess(response, file, fileList) {
           console.log(response)
        this.form.site_cover_image = response.result.url;
      },
  
      //移除图片的事件
      imgRemove(file, fileList) {
        this.form.site_cover_image = "";
      },
             //添加事件 退出弹窗
      userClose(e) {
        this.form = this.$options.data().form;
          this.updataFalg=false;
          this.lookFalg=false;
          this.addFlag=false;
        (this.fileList = []), console.log("close");
      },
      /**-----------------网络请求生命周期函数--------------------- */
      //地区列表
      async PostRegionalList(data){
           let res=await PostRegionalList(data);
           this.siteIdArr=res.data.result.data;
      },
          //站点增加
          async PostSiteAdd(dataObj){
         let res=await PostSiteAdd(dataObj);
         if(res.data.status==1){
            this.dialogFormVisible=false;
            this.$message({
          message: res.data.message,
          type: 'success'
        });
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
      };
       this.PostSiteList(data);
         }else{
            this.$message.error(res.data.message)
         }
      },
           //站点列表修改
           async PostSiteUpdate(dataObj){
         let res=await PostSiteUpdate(dataObj);
         if(res.data.status==1){
            this.dialogFormVisible=false;
            this.$message({
          message: res.data.message,
          type: 'success'
        });
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
      };
       this.PostSiteList(data);
         }else{
            this.$message.error(res.data.message)
         }
      },
        //站点列表删除
        async PostSiteDelete(dataObj){
         let res=await PostSiteDelete(dataObj);
         if(res.data.status==1){
            this.dialogFormVisible=false;
            this.$message({
          message: res.data.message,
          type: 'success'
        });
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
      };
       this.PostSiteList(data);
         }else{
            this.$message.error(res.data.message)
         }
      },
       //站点列表
      async PostSiteList(data){
          let res=await PostSiteList(data);
          console.log(res.data)
          this.tableData=res.data.result.data;
          this.getInfo={
              page_code:res.data.result.last_page,
              list_rows:res.data.result.per_page
          }
            this.exp.num=res.data.result.total;
          //   this.dialogFormVisible=false;
          // console.log(res.data.data);
      },
    },
  
    
    beforeCreate() {}, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
      var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
      };
      this.PostRegionalList(data);
       this.PostSiteList(data);
    },
  
    beforeMount() {}, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {},
    beforeUpdate() {}, //生命周期 - 更新之前
    updated() {}, //生命周期 - 更新之后
    activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    beforeDestroy() {}, //生命周期 - 销毁之前
    destroyed() {}, //生命周期 - 销毁完成
  };
  </script>
  
  <style scoped>
  .page {
    margin-top: 20px;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .set-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .set-page-left {
    color: #0000006d;
    font-size: 14px;
    font-weight: 400;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .diaBox {
    display: flex;
    justify-content: center;
  }
  .imgUrl {
    margin-left: 50%;
    transform: translateX(-50%);
    width: 250px;
    height: 150px;
    overflow: hidden;
    text-align: center;
  }
  </style>  